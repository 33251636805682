const dateFormats = {
  '01': 'DD',
  '01 Jan 2023': 'DD MMM YYYY',
  '01:01': 'hh:mm',
  '01:01 AM': 'hh:mm A',
  '01.01.2023': 'DD.MM.YYYY',
  '01.01.2023 01:01 AM': 'DD.MM.YYYY hh:mm A',
  '1 Jan': 'D MMM',
  '2023-01-01': 'YYYY-MM-DD',
  '23:59': 'HH:mm',
  '23:59:01': 'HH:mm:ss',
  AM: 'A',
  Jan: 'MMM',
  'Jan 1': 'MMM D',
  'Jan 1, 2023': 'MMM D, YYYY',
  January: 'MMMM',
  'January 1, 2023': 'MMMM D, YYYY',
  'January 2023': 'MMMM YYYY',
  'Sun 1 Jan': 'ddd D MMM',
  'Sun, 1 Jan 2024': 'ddd, D MMM YYYY',
  'Sun, Jan 1 2024': 'ddd, MMM D YYYY',
  'Sunday 1 Jan': 'dddd D MMM',
  'Sunday, 1 Jan 2023': 'dddd, D MMM YYYY',
}

export default dateFormats
