export enum TimetableFieldSort {
  DEPARTURE = 'departure_datetime',
  PRICE = 'cheapest_coach_class.cheapest_fare.price.number',
  SPEED = 'running_time',
}

export enum OrderBySort {
  ASC = 'asc',
  DESC = 'desc',
}
